import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../WhoWeAre/Headings.js";
import { SectionDescription } from "./Typography.js";
import { Container, ContentWithPaddingXl } from "./Layouts.js";
//import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import Cloudservices from "../../Images/Cloud-consulting-and-services.svg";
import RealEstate from "../../Images/Real-estate-software-development.svg";
import ProductEngg from "../../Images/Product-engineering.svg";
import ApplicatonSupport from "../../Images/Application-maintenance-&-support.svg";
import Dedicated from "../../Images/Dedicated-development-teams.svg";
import Digital from "../../Images/Digital-transformation-services.svg";
//import { ReactComponent as SvgDecoratorBlob3 } from "../../Images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center  mx-auto -mt-16`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 my-2`}
`;

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 inline-block`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-5 px-1  transition-transform duration-300 hover:cursor-pointer p-5 hover:shadow-[rgba(0, 0, 0, 0.24) 0px 3px 8px;] hover:bg-cream-500`}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100 `}
    img {
      ${tw`w-8 h-8 hover:cursor-pointer transform hover:scale-125`}
    }
  }

  .title {
    ${tw`mt-4 text-xl font-medium leading-none h-5`}
  }

  .description {
    ${tw`mt-4 text-lg text-gray-700 text-justify `}
  }

    .imageCss {
${tw`w-40! h-40!`}
}

    .imageContainerCss {
${tw`p-2!`}
}

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
}

`;

//const DecoratorBlob = styled(SvgDecoratorBlob3)`
//  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
//`;

const WhatWeDo = (props) => {

    let cards =
        [
            {
                imageSrc: Dedicated,
                title: "Dedicated development teams",
                description: "Expand your IT capabilities with our dedicated offshore teams.",
                //url: "https://google.com"
            },
            {
                imageSrc: RealEstate,
                title: "Real estate software development",
                description: "We bring our deep expertise of the U.S. real estate market to your advantage.",
                //url: "https://reddit.com"
            }
            ,
            
            {
                imageSrc: ApplicatonSupport,
                title: "Application maintenance & support ",
                description: "Free up your core teams to build new while we take care of maintaining and upgrading your existing or legacy applications.",
                //url: "https://reddit.com"
            }
            , {
                imageSrc: Cloudservices,
                title: "Cloud consulting and services",
                description: "Increase resiliency and make your business global by benefiting from moving your systems to the cloud.",
                //url: "https://reddit.com"
            }
            ,
            {
                imageSrc: Digital,
                title: "Digital transformation services ",
                description: "Leverage new technologies from maps, messaging and other online tools to enhance your business.",
                //url: "https://timerse.com"
            }
            ,
            {
                imageSrc: ProductEngg,
                title: "Product engineering",
                description: "With 25 years of expertise in helping customers build enterprise applications and taking product to market, our principals will help drive your innovation.",
                //url: "https://reddit.com"
            }
        ],
        //linkText = "Learn More",
        heading = "",
        subheading = "",
        description = "",
        imageContainerCss = null,
        imageCss = null

    return (
        <Container id={props.id}>
                <Heading>
                What <HighlightedText className="transform -skew-x-12">We Do</HighlightedText>
            </Heading>
            <ContentWithPaddingXl>
                {subheading && <Subheading>{subheading}</Subheading>}
                {heading && <Heading>{heading}</Heading>}
                {description && <Description>{description}</Description>}
                <ThreeColumnContainer>
                    {cards.map((card, i) => (
                        <Column key={i}>
                            <Card href={card.url} className="transform hover:scale-110">
                                <span className="imageContainer" css={imageContainerCss} className="imageContainerCss">
                                    <img src={card.imageSrc} alt="" css={imageCss} className="imageCss" />
                                </span>
                                <span className="title" style={{float:"left"}}>{card.title}</span>
                                <p className="description">{card.description}</p>
                                {/*{linkText && (*/}
                                {/*  <span className="link">*/}
                                {/*    */}{/*<span>{linkText}</span>*/}
                                {/*    <ArrowRightIcon className="icon" />*/}
                                {/*  </span>*/}
                                {/*)}*/}
                            </Card>
                        </Column>
                    ))}
                </ThreeColumnContainer>
            </ContentWithPaddingXl>
            {/*<DecoratorBlob />*/}
        </Container>
    );
};

export default WhatWeDo;

