import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../WhoWeAre/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../Techstack/Buttons.js";
import EmailIllustrationSrc from "../../Images/email-illustration.svg";
import successIcon from "../../Images/successIcon.png";
import failureIcon from "../../Images/failureIcon.png";
import errorIcon from "../../Images/errorIcon.svg";

const Container = tw.div`relative m-5`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-8`;

const Column = tw.div`w-full lg:mr-16 md:mr-12`;

const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;

const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 inline-block`;

const Headings = tw(SectionHeading)`mt-0`;

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;

const Heading = tw(SectionHeading)` font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`md:mt-5 text-sm flex flex-col max-w-sm mx-auto md:mx-0`

const Input = tw.input`mt-4 p-1 first:mt-0 border-b-2 py-2 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-[3.5rem] mt-6 border-b-2 hocus:border-primary-500 p-1`}
`

const ModalCard = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none m-1`

const Modal = tw.div`relative my-6 mx-auto max-w-3xl`

const ModalContent = tw.div`border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-100 outline-none focus:outline-none`

const ModalBody = tw.div`relative px-12 py-2 flex-auto`

const BodyPara1 = tw.p`text-gray-900 text-lg leading-relaxed font-semibold flex items-start justify-start pt-5`

const BodyPara2 = tw.p`text-gray-900 text-lg leading-relaxed font-semibold flex items-start justify-start pt-1 ml-[2.75em]`

const ModalFooter = tw.div`flex items-center justify-center p-3 rounded-b`

const FooterButton = tw.button`bg-green-600 text-white active:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg hover:bg-green-500 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`

const FooterButton2 = tw.button`bg-red-700 text-white active:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg hover:bg-red-600 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`

const ModalBackground = tw.div`opacity-25 fixed inset-0 z-40 bg-gray-900`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-6`

const Error = tw.p`text-red-600 text-left`

const ContactUs = (props) => {

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [isEmailError, setEmailError] = useState(false);
    const [isNameError, setNameError] = useState(false);
    const [isSubjectError, setSubjectError] = useState(false);
    const [isMessageError, setMessageError] = useState(false);
    const [btnTxt, setBtnTxt] = useState("Send");

    let subheading = "Drop us a message",
        heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
        description = "We are just an email away from working together! We look forward to hearing from you and starting a discussion.",
        submitButtonText = btnTxt,
        textOnLeft = true

    const form = useRef();

    const sendEmail = (e) => {

        if (e.target[0].value.trim() === "") {
            setEmailError(true);
        }

        if (e.target[1].value.trim() === "") {
            setNameError(true);
        }

        if (e.target[2].value.trim() === "") {
            setSubjectError(true);
        }

        if (e.target[3].value.trim() === "") {
            setMessageError(true);
        }

        e.preventDefault();

        var fieldErrCondition = e.target[0].value.trim() === "" || e.target[1].value.trim() === "" || e.target[2].value.trim() === "" || e.target[3].value.trim() === "";

        if (!fieldErrCondition) {
            setBtnTxt("Sending..");
            emailjs.sendForm('service_waz84lv', 'template_tmatm2j', form.current, 'z92oTcMjPo7NBZn86')
                .then((result) => {
                    //console.log(result.text);
                    setShowSuccessModal(true);
                    setBtnTxt("Send");
                }, (error) => {
                    //console.log(error.text);
                    setShowFailureModal(true);
                    setBtnTxt("Send");
                });
        }
    };

    const handleChange = (e) => {

        let name = e.target.id;
        let value = e.target.value;

        if (name === "user_email" && value !== "") {
            setEmailError(false);
        }

        if (name === "user_email" && value === "") {
            setEmailError(true);
        }

        if (name === "user_name" && value !== "") {
            setNameError(false);
        }

        if (name === "user_name" && value === "") {
            setNameError(true);
        }

        if (name === "subject" && value !== "") {
            setSubjectError(false);
        }

        if (name === "subject" && value === "") {
            setSubjectError(true);
        }

        if (name === "message" && value !== "") {
            setMessageError(false);
        }

        if (name === "message" && value === "") {
            setMessageError(true);
        }

    }

    return (
        <Container id={props.id}>

            {showSuccessModal ? (
                <>
                    <ModalCard>
                        <Modal>
                            <ModalContent>
                                <ModalBody>
                                    <BodyPara1>
                                        <img src={successIcon} alt="successMessage" width="30em" height="30em" style={{ marginRight: "1em" }} />
                                        Thank you for your message.
                                    </BodyPara1>
                                    <BodyPara2> We will get in touch with you shortly. Email successfully sent.
                                    </BodyPara2>
                                </ModalBody>
                                <ModalFooter>
                                    <FooterButton
                                        type="button"
                                        onClick={() => setShowSuccessModal(false)}
                                    >
                                        OK
                                    </FooterButton>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </ModalCard>
                    <ModalBackground></ModalBackground>
                </>
            ) : null}

            {showFailureModal ? (
                <>
                    <ModalCard>
                        <Modal>
                            <ModalContent>
                                <ModalBody>
                                    <BodyPara1>
                                        <img src={failureIcon} alt="failureMessage" width="30em" height="30em" style={{ marginRight: "1em" }} />
                                        We had problems sending the email.
                                    </BodyPara1>
                                    <BodyPara2>Please try again or please send an email to info@karibettu.com
                                    </BodyPara2>
                                </ModalBody>
                                <ModalFooter>
                                    <FooterButton2
                                        type="button"
                                        onClick={() => setShowFailureModal(false)}
                                    >
                                        OK
                                    </FooterButton2>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </ModalCard>
                    <ModalBackground></ModalBackground>
                </>
            ) : null}

            <Headings>
                <HighlightedText className="transform -skew-x-12">Contact</HighlightedText> Us
            </Headings>
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={EmailIllustrationSrc} />
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        {description && <Description>{description}</Description>}
                        <Form ref={form} onSubmit={(e) => sendEmail(e)} onChange={(e) => handleChange(e)}>
                            <label htmlFor="user_email" className="sr-only">Email</label>
                            <Input type="email" name="user_email" id="user_email" placeholder="Your Email Address" />
                            {isEmailError && <Error><img style={{ display: "inline-flex", position: "relative", top: "-1.5px" }} alt="errorIcon" src={errorIcon} width="12em" height="15em" /> This field cannot be left blank</Error>}
                            <label htmlFor="user_name" className="sr-only">Name</label>
                            <Input type="text" name="user_name" id="user_name" placeholder="Full Name" />
                            {isNameError && <Error><img style={{ display: "inline-flex", position: "relative", top: "-1.5px" }} alt="errorIcon" src={errorIcon} width="12em" height="15em" /> This field cannot be left blank</Error>}
                            <label htmlFor="subject" className="sr-only">Subject</label>
                            <Input type="text" name="subject" id="subject" placeholder="Subject" />
                            {isSubjectError && <Error><img alt="errorIcon" style={{ display: "inline-flex", position: "relative", top: "-1.5px" }} src={errorIcon} width="12em" height="15em" /> This field cannot be left blank</Error>}
                            <label htmlFor="message" className="sr-only">Message</label>
                            <Textarea name="message" id="message" placeholder="Your Message Here" />
                            {isMessageError && <Error><img alt="errorIcon" style={{ display: "inline-flex", position: "relative", top: "-1.5px" }} src={errorIcon} width="12em" height="15em" /> This field cannot be left blank</Error>}
                            <SubmitButton disabled={btnTxt === "Sending.."} type="submit" className="btnDisabled">{submitButtonText}</SubmitButton>
                        </Form>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
}
export default ContactUs;