import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "../WhoWeAre/Headings.js";
//import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "../Techstack/Buttons.js";
import Frontend_img from '../../Images/reactvacancy.svg';
import Backend_img from '../../Images/dotnetvacancy.svg';

const Container = tw.div`relative m-5`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-16`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-5 xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
    tw`w-full xl:w-5/12`,
    props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
//const PrimaryLink = styled(PrimaryLinkBase)`
//  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
//  svg {
//    ${tw`ml-2 w-5 h-5`}
//  }
//`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 inline-block`;

const Headings = tw(SectionHeading)`mt-0`;
const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs border p-2`;
const CardImage = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`h-64 bg-contain bg-no-repeat bg-center rounded`
]);

const CardText = tw.div`mt-4`;

//const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600 mt-2`;

const CardTitle = tw.h5`text-sm font-semibold mt-4 h-28 overflow-auto text-justify `;

const CardAction = tw(PrimaryButtonBase)`w-full mt-6`;

const Career = (props) => {

    let subheading = "Join our team",
        headingHtmlComponent = (
            <>
                We've done some <span tw="text-primary-500">amazing projects.</span>
            </>
        ),
        description = "Karibettu offers a challenging, satisfying and learning environment. We invite you to join our team.",
        //linkText = "View all Projects",
        cardLinkText = "Apply",
        textOnLeft = false

    const cards = [
        {
            imageSrc: Frontend_img,
            company: "Front-end developer",
            type: "React, Redux, JS, CSS",
            title: "Developers with 0-4 years experience designing and creating JavaScript-based applications for web and mobile environments. Front-end development skills in React, Redux, Axios, Javascript, etc."
        },
        {
            imageSrc: Backend_img,
            company: ".Net developer",
            type: "C#, .NetCore, SQL, API development",
            title: "Developers with 0-4 years experience across Microsoft technologies such as DotNetCore, API development, Active Directory, SQL Server, C#, ASP.NET, MVC, etc."
        }
    ];

    const scrollToContactUs = () => {
        document.getElementById("contact").scrollIntoView({
            behaviour: "smooth"
        });
    }

    return (
        <Container id={props.id}>
            <Headings>
                <HighlightedText className="transform -skew-x-12">Join</HighlightedText> Us
            </Headings>
            <Content>
                <ThreeColumn>
                    <HeadingColumn textOnLeft={textOnLeft}>
                        <HeadingInfoContainer>
                            <Subheading>{subheading}</Subheading>
                            <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
                            <HeadingDescription>{description}</HeadingDescription>
                            {/*<PrimaryLink>*/}
                            {/*    {linkText} <ArrowRightIcon />*/}
                            {/*</PrimaryLink>*/}
                        </HeadingInfoContainer>
                    </HeadingColumn>
                    {cards.map((card, index) => (
                        <CardColumn key={index}>
                            <Card>
                                <CardImage imageSrc={card.imageSrc} />
                                <CardText>
                                        <CardCompany>{card.company}</CardCompany>
                                        <CardType>{card.type}</CardType>
                                    <CardTitle>{card.title}</CardTitle>
                                    <CardAction onClick={() => scrollToContactUs()}>{cardLinkText}</CardAction>
                                </CardText>
                            </Card>
                        </CardColumn>
                    ))}
                </ThreeColumn>
            </Content>
        </Container>
    );
}

export default Career;
