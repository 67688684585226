import React, { useEffect } from "react";
import tw from 'twin.macro';
import styled from "styled-components";
import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from "./Navbar/Navbar.js";
//import home from '../Images/home.jpg';
import home from '../Images/homeLite.avif';
//import WhoWeAre from './WhoWeAre/WhoWeAre.js';
//import WhatWeDo from "./WhatWeDo/WhatWeDo.js";
import ContactUs from './Contact/ContactUs.js';
import Footerpage from './Footer/Footer.js';
//import TechStack from './Techstack/Techstack.js';
import Career from './Career/Career.js';
import Typed from "react-typed";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none text-gray-100 z-10`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative h-85 bg-cover`}
  background-image: url(${home});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-orange-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative sm:px-8 max-w-screen-2xl mx-10`;
const TwoColumn = tw.div`flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Heading1 = styled.h2`
  ${tw`text-3xl font-bold flex flex-col items-center`}
`;

const Heading2 = styled.h2`
  ${tw`text-xl block flex flex-col items-center`}
`;

const Welcome = styled.div`
  ${tw`m-5`}
`;

const HighlightedText = tw.span`bg-gray-100 text-primary-500 px-4 pb-2 inline-block`;

const HighlightedText1 = tw.div`text-black px-4 inline-block`;

const HighlightedSubText = tw.div`text-black px-4 pb-5 inline-block`;

const HighlightedText2 = tw.div`px-4 pb-2 lg:px-80`;

const IquraLink = tw.a`px-4 pb-2 lg:px-80 text-primary-500 font-medium mb-5`;

//const SlantedBackground = styled.span`
//  ${tw`relative text-primary-500 px-4 -mx-4 py-2 `}
//  &::before {
//    content: "";
//    ${tw`-skew-x-12`}
//  }
//`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 mt-48 text-gray-100 border-blue-800 font-medium text-lg border-l-4`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 font-bold rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0`;

//var prevScrollpos = window.pageYOffset;
//window.onscroll = function () {
//    console.log(prevScrollpos)
//    var currentScrollPos = window.pageYOffset;
//    console.log(currentScrollPos)
//    if (prevScrollpos > currentScrollPos) {
//        document.getElementById("navbar").style.top = "0px"
//    }
//    else {
//        document.getElementById("navbar").style.top = "-75px";
//    }
//    prevScrollpos = currentScrollPos;
//}

//var prevScrollpos = window.pageYOffset;
//window.onscroll = function () {
//    var currentScrollPos = window.pageYOffset;
//    if (prevScrollpos === 0 || currentScrollPos === 0) {
//        const btn = document.getElementById("header").classList.add("navbarsStyle")

//        //const styles = {
//        ////    color: "rgba(255,255,255,1)",
//        ////    border: "none",
//        ////    backgroundColor: "transparent",
//        ////    backdropFilter: "blur(0px)"
//        //};

//        //Object.assign(btn.style, styles);
//    }
//    prevScrollpos = currentScrollPos;
//}

//if (scrolled) {
//    document.body.classList.add("scrolled");
//} else {
//    document.body.classList.remove("scrolled");
//}
const scrollToHome = () => {
    if (window.pageYOffset === 0) {
        document.getElementById("header").classList.remove("navbarStyle");
        document.getElementById("logoFontFamily").classList.remove("navbarLogo");
    }
}
const Home = () => {

    useEffect(() => {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }, []);

    const scrollToWhoWeAre = () => {

        document.getElementById("header").classList.add("navbarStyle");
        document.getElementById("whoweare").scrollIntoView({
            behaviour: "smooth"
        });
        window.addEventListener('scroll', scrollToHome);
        document.getElementById("logoFontFamily").classList.add("navbarLogo");
    }

    const scrollToWhatWeDo = () => {

        document.getElementById("header").classList.add("navbarStyle");
        document.getElementById("whatwedo").scrollIntoView({
            behaviour: "smooth"
        });
        window.addEventListener('scroll', scrollToHome);
        document.getElementById("logoFontFamily").classList.add("navbarLogo");
    }

    const scrollToTechstack = () => {
        document.getElementById("header").classList.add("navbarStyle");
        document.getElementById("techstack").scrollIntoView({
            behaviour: "smooth"
        });
        window.addEventListener('scroll', scrollToHome);
        document.getElementById("logoFontFamily").classList.add("navbarLogo");
    }

    const scrollToCareer = () => {
        document.getElementById("header").classList.add("navbarStyle");
        document.getElementById("career").scrollIntoView({
            behaviour: "smooth"
        });
        window.addEventListener('scroll', scrollToHome);
        document.getElementById("logoFontFamily").classList.add("navbarLogo");
    }

    const scrollToContactUs = () => {
        document.getElementById("header").classList.add("navbarStyle");
        document.getElementById("contact").scrollIntoView({
            behaviour: "smooth"
        });
        window.addEventListener('scroll', scrollToHome);
        document.getElementById("logoFontFamily").classList.add("navbarLogo");
    }


    const navLinks = [
        <NavLinks key={1}>
            {/*<NavLink onClick={() => scrollToWhoWeAre()}>Who we are</NavLink>
            <NavLink onClick={() => scrollToWhatWeDo()}>What we do</NavLink>
            <NavLink onClick={() => scrollToTechstack()}>Techstack</NavLink>*/}
            <NavLink onClick={() => scrollToCareer()}>Join Us</NavLink>
            <NavLink onClick={() => scrollToContactUs()}>Contact</NavLink>
        </NavLinks >
    ];

    return (
        <>
            <Container id="navbar">
                <OpacityOverlay />
                <HeroContainer>
                    <StyledHeader links={navLinks} />
                    <TwoColumn>
                        <LeftColumn>
                            <Notification>
                                <Typed
                                    strings={["Bespoke software development company ", "Reliable, experienced and cost-effective team"]}
                                    typeSpeed={50}
                                    backSpeed={50}
                                    loop
                                /></Notification>
                            <Heading>
                                <span>Hire the best</span>
                                <br />
                                <HighlightedText className="transform -skew-x-12">Development Team.</HighlightedText>
                            </Heading>
                            <PrimaryAction onClick={() => scrollToContactUs()}>Hire Us</PrimaryAction>
                        </LeftColumn>
                    </TwoColumn>
                </HeroContainer>
            </Container>
            <Welcome>
                <Heading1>
                    <HighlightedText1>Welcome to Karibettu Private Limited,
                    </HighlightedText1>
                    <HighlightedSubText>located in Bangalore, India
                    </HighlightedSubText>
                </Heading1>
                <Heading2>
                    <HighlightedText2>Karibettu is an associate company of
                        Iqura Technologies.
                    </HighlightedText2>
                    <HighlightedText2>With its corporate office in Singapore, Iqura is known for providing customers with reliable, robust and cost-effective solutions in software outsourcing, cloud services and real estate technology services.</HighlightedText2>
                    <IquraLink href="https://www.iqura.tech" target="_blank"> Click here for more information about our services.</IquraLink>
                </Heading2>
            </Welcome>
            {/*<WhoWeAre id="whoweare" />
            <WhatWeDo id="whatwedo" />
            <TechStack id="techstack" />*/}
            <Career id="career" />
            <ContactUs id="contact" />
            <Footerpage />
        </>
    );
};

export default Home;