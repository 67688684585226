//import React from "react";
//import ReactDOM from "react-dom";
//import App from "./App";
//import Modal from "react-modal";
//import "./index.css";

//Modal.setAppElement("#root");

//ReactDOM.render(
//    <App />,
//    document.getElementById("root")
//);


import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Modal from "react-modal";

const root = ReactDOM.createRoot(document.getElementById("root"));
Modal.setAppElement("#root");
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
