import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "./Headings.js";
import Whoweare from "../../Images/whoweare.svg";
import { ReactComponent as SvgDotPattern } from "../../Images/dot-pattern.svg";

const Container = tw.div`relative m-5`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 px-8 md:h-auto relative lg:mr-24`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:order-last`
]);
const Headings = tw(SectionHeading)`mt-16`;
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full `
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-justify md:text-justify text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 inline-block`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
    tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);


const WhoWeAre = (props) => {
        let subheading = "Our Track Record",
        heading = (
            <>
                We have been doing this <wbr /> since <span tw="text-primary-500">1998.</span>
            </>
        ),
        description = "Over the last 24 years, we have successfully built and delivered projects for customers ranging from Fortune 500 companies, technology product startups to small and mid-size businesses. We build long-term partnerships with our customers where we are a stakeholder in their aspirations. Your success is our success!",

        imageSrc = Whoweare,
        imageCss = null,
        imageContainerCss = null,
        imageDecoratorBlob = false,
        imageDecoratorBlobCss = null,
        imageInsideDiv = true,
        textOnLeft = false
    return (
        <Container id={props.id}>
            <Headings>
                Who <HighlightedText className="transform -skew-x-12">We Are</HighlightedText>
            </Headings>
            <TwoColumn css={!imageInsideDiv && tw`md:items-center lg:items-center`}>
                <ImageColumn css={imageContainerCss}>
                    {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
                    {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        <Description>{description}</Description>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
            </Container>
    );
}; 

export default WhoWeAre;