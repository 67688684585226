import React, { useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "./useAnimatedNavToggler.js";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center py-2
   fixed left-0 w-screen
`;

export const NavLinks = tw.div`mr-3`;

/* hocus: stands for "on hover or focus"
* hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
*/
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 text-xl!
  font-semibold tracking-wide transition duration-300 flex sm:flex md:flex lg:inline-block
  pb-1 border-b-2 border-transparent hover:border-primary-700 hocus:text-primary-700
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-200
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline 
  border-b-0
`;

export const LogoLink = tw.h5`flex items-center font-black border-b-0 lg:ml-16 md:ml-16 sm:ml-8 text-5xl ml-2 font-bold`;

export const MobileNavLinksContainer = tw.nav`items-center justify-between px-2`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300 
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw` block items-center text-left`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const scrollFunc = () => {
    
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos < currentScrollPos || prevScrollpos > currentScrollPos) {
            const btn = document.getElementById("header");
            document.getElementById("logoFontFamily").style.color = "black";

            // create styles object
            const styles = {
                color: "rgba(0,0,0,1)",
                borderBottom: "1px solid rgba(185,185,185,0.5)",
                backgroundColor: "rgba(255,255,255,0.5)",
                backdropFilter: "blur(10px)",
                transition: "0.1s linear"
            };

            // apply styles to the button
            Object.assign(btn.style, styles);
        }
        if (prevScrollpos === 0 || currentScrollPos === 0) {
            const btn = document.getElementById("header");
            const btn2 = document.getElementById("logoFontFamily");

            // create styles object
            const styles = {
                color: "rgba(255,255,255,1)",
                border: "none",
                backgroundColor: "transparent",
                backdropFilter: "blur(0px)"
            };

            const styles2 = {
                color: "#D86B4E",
                border: "none",
                backgroundColor: "transparent",
                backdropFilter: "blur(0px)"
            }

            // apply styles to the button
            Object.assign(btn.style, styles);
            Object.assign(btn2.style, styles2);
        }
        prevScrollpos = currentScrollPos;
    }
}
const Navbar = ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
    /*
     * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
     * This links props should be an array of "NavLinks" components which is exported from this file.
     * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
     * This allows this Header to be multi column.
     * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
     * Left part will be LogoLink, and the right part will be the the NavLinks component you
     * supplied.
     * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
     * You can also choose to directly modify the links here by not passing any links from the parent component and
     * changing the defaultLinks variable below below.
     * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
     */

    const scrollTop = () => { // to scroll top the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
    const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

    const defaultLogoLink = (
        <LogoLink href="#" className="logoFontFamily" id="logoFontFamily" onClick={() => scrollTop() }>
            Karibettu
        </LogoLink>
    );

    logoLink = logoLink || defaultLogoLink;

    useEffect(() => {
        scrollFunc();
    }, []);

    return (
        <Header className={className || "header-light"} id="header">
            <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
                {logoLink}
                {links}
            </DesktopNavLinks>

            <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer} style={{height:"8vh"}}>
                {logoLink}
                <MobileNavLinks onClick={toggleNavbar} initial={{ x: "150%", display: "block" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
                    {links}
                </MobileNavLinks>
                <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"} style={{position: "relative", left:"84vw", bottom:"2.5em"}}>
                    {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
                </NavToggle>
            </MobileNavLinksContainer>
        </Header>
    );
};

/* The below code is for generating dynamic break points for navbar.
* Using this you can specify if you want to switch
* to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
* Its written like this because we are using macros and we can not insert dynamic variables in macros
*/

const collapseBreakPointCssMap = {
    sm: {
        mobileNavLinks: tw`sm:hidden`,
        desktopNavLinks: tw`sm:flex`,
        mobileNavLinksContainer: tw`sm:hidden`
    },
    md: {
        mobileNavLinks: tw`md:hidden`,
        desktopNavLinks: tw`md:flex`,
        mobileNavLinksContainer: tw`md:hidden`
    },
    lg: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`
    },
    xl: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`
    }
};

export default Navbar;
