import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home.js";
import WhoWeAre from "./components/WhoWeAre/WhoWeAre.js";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo.js";
import Techstack from "./components/Techstack/Techstack.js";
import ContactUs from "./components/Contact/ContactUs.js";
import Footer from "./components/Footer/Footer.js";

const App = () => {

    return (

        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/whoweare" element={<WhoWeAre />} />
                <Route path="/whatwedo" element={<WhatWeDo />} />
                <Route path="/techstack" element={<Techstack />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/footer" element={<Footer />} />
            </Routes>
        </Router>
    );
}
export default App;
