import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../WhoWeAre/Headings.js";
import { ReactComponent as CloudIcon } from "feather-icons/dist/icons/cloud-lightning.svg";
import { ReactComponent as AnchorIcon } from "feather-icons/dist/icons/anchor.svg";
import { ReactComponent as UpArrow } from "feather-icons/dist/icons/chevron-up.svg";
import { ReactComponent as DownArrow } from "feather-icons/dist/icons/chevron-down.svg";
import React_logo from '../../Images/React_logo.png';
import Redux_logo from '../../Images/Redux_logo.png';
import JS_logo from '../../Images/JS_logo.png';
import dotNetCore_logo from '../../Images/dotNetCore_logo.png';
import cSharp_logo from '../../Images/cSharp_logo.png';
import sqlServer_logo from '../../Images/sqlServer_logo.png';
import aws_logo from '../../Images/aws_logo.png';
import gcp_logo from '../../Images/gcp_logo.png';
import azure_logo from '../../Images/azure_logo.png';
import jquery_logo from '../../Images/jquery_logo.png';
import bootstrap_logo from '../../Images/bootstrap_logo.png';
import sass_logo from '../../Images/sass_logo.png';
import tailwind_logo from '../../Images/tailwind_logo.png';
import css_logo from '../../Images/css_logo.png';
import html_logo from '../../Images/html_logo.jpg';
import nodejs_logo from '../../Images/nodejs_logo.png';
//import github_logo from '../../Images/github_logo.png';
import mvc_logo from '../../Images/mvc_logo.png';
import vbDotNet_logo from '../../Images/vbDotNet_logo.png';
import aspDotNet_logo from '../../Images/aspDotNet_logo.png';
import ajax_logo from '../../Images/ajax_logo.png';
import webApi_logo from '../../Images/webApi_logo.png';

const Container = tw.div`relative m-5`;

const TwoColumn = tw.div`flex flex-col md:flex-row sm:flex-col items-start justify-between max-w-screen-xl mx-auto py-10 md:py-8 lg:items-center md:items-center`;

const Column = tw.div`w-full max-w-md mx-auto max-w-none md:mx-0`;

const ImageColumn = tw(Column)`rounded-full bg-gray-200 w-32 p-4 sm:p-3 sm:w-24 lg:p-4 mb-5 mt-3 lg:w-32 md:w-24 md:p-3 xs:w-12 xs:p-1`;

const ImageColumn1 = tw(Column)`rounded-full bg-gray-200 w-32 p-4 sm:p-3 sm:w-24 lg:p-2 mb-5 mt-3 lg:w-20 md:w-24 md:p-3 xs:w-12 xs:p-1 sm:items-start xs:items-start`;

const ImageColumn2 = tw(Column)`rounded-full bg-gray-200 w-32 p-4 sm:p-3 sm:w-24 lg:p-2 mb-5 mt-3 lg:w-20 md:w-24 md:p-3 xs:w-12 xs:p-1 items-end sm:items-start xs:items-start`;

const Title = tw.p`-mt-4 text-sm text-center xs:mx-auto`;

const TextColumn = styled(Column)(props => [
    tw`w-6/12 mt-16 mt-0`,
    props.textOnLeft ? tw`mr-12 lg:mr-16 order-first` : tw`ml-12 lg:ml-16 order-last`
]);

const Image = styled.img(props => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`,
    tw(Column)`rounded-full bg-gray-200 w-32 p-4 sm:p-3 sm:w-24 lg:p-4 my-5 lg:w-32 md:w-24 md:p-3 xs:w-12 xs:p-1`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left lg:mr-24 md:mr-12 mr-0 sm:mr-12`;

const Headings = tw(SectionHeading)`mt-0`;

const Subheading = tw(SubheadingBase)`text-left md:text-left`;

const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-left md:text-left leading-tight`;

const Description = tw.p`mt-8 text-justify md:text-justify sm:text-justify text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`md:mx-0 flex flex-col xs:flex-col lg:flex-row max-w-xs lg:max-w-none`;

const Feature = tw.div`mt-10 lg:mt-8 flex items-start md:items-start flex-col md:mr-8 last:mr-0`;

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 inline-block`;

const FeatureHeadingContainer = tw.div`flex items-center`;

const ImageSpan = tw.span`mx-5`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
        props.iconRoundedFull && tw`rounded-full`,
        props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
    ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const FeatureHeading = tw.div`ml-3 font-bold text-xl`;

const ImageCard = tw.div`flex justify-start pb-2 mx-4`;

const ImageContainer = tw.div`block bg-gray-200`;

const FeatureDescription = tw.div`mt-4 text-justify md:text-justify  sm:text-justify text-gray-600 leading-relaxed`;

const Techstack = (props) => {

    const [isShow1, setShow1] = useState(false);
    const [isShow2, setShow2] = useState(false);
    const [isShow3, setShow3] = useState(false);

    let subheading = "Our Expertise",
        heading = (
            <>
                Designed & Developed by <span tw="text-primary-500">Professionals.</span>
            </>
        ),
        description = "We build enterprise-critical web applications that are secure, high-performance, responsive and accessible. We are technology-agnostic and will provide impartial advise on the best technology stack. If you have legacy Microsoft applications to support and maintain, we have two decades of experience to ensure smooth operations and a graded upgrade path.",
        imageSrc = React_logo,
        imageSrc2 = Redux_logo,
        imageSrc3 = JS_logo,
        imageSrc4 = dotNetCore_logo,
        imageSrc5 = cSharp_logo,
        imageSrc6 = sqlServer_logo,
        imageSrc7 = aws_logo,
        imageSrc8 = gcp_logo,
        imageSrc9 = azure_logo,
        imageSrc10 = jquery_logo,
        imageSrc11 = bootstrap_logo,
        imageSrc12 = sass_logo,
        imageSrc13 = tailwind_logo,
        imageSrc14 = css_logo,
        imageSrc15 = html_logo,
        imageSrc16 = nodejs_logo,
        //imageSrc17 = github_logo,
        imageSrc18 = mvc_logo,
        imageSrc19 = vbDotNet_logo,
        imageSrc20 = aspDotNet_logo,
        imageSrc21 = ajax_logo,
        imageSrc22 = webApi_logo,
        imageRounded = true,
        imageBorder = false,
        imageShadow = false,
        textOnLeft = true,
        features = null,
        iconRoundedFull = true,
        iconFilled = true,
        iconContainerCss = null

    const defaultFeatures = [
        {
            Icon: CloudIcon,
            title: "Cloud-based",
            description: "Cloud-hosted web applications built using contemporary technologies and tools.",
            iconContainerCss: tw`bg-teal-300 text-teal-800`
        },
        {
            Icon: AnchorIcon,
            title: "Legacy",
            description: "Reliable and dedicated support, maintenance and upgrades for your legacy systems.",
            iconContainerCss: tw`bg-red-300 text-red-800`
        }
    ];

    const handleButton1 = () => {
        setShow1(isShow1 ? false : true);
        setShow2(false);
        setShow3(false);
    }

    const handleButton2 = () => {
        setShow2(isShow2 ? false : true);
        setShow1(false);
        setShow3(false);
    }

    const handleButton3 = () => {
        setShow3(isShow3 ? false : true);
        setShow1(false);
        setShow2(false);
    }

    if (!features) features = defaultFeatures;

    return (
        <Container id={props.id}>
            <Headings>
                <HighlightedText className="transform -skew-x-12">Techstack</HighlightedText>
            </Headings>
            <TwoColumn>
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item bg-white border border-cream-100">
                        <h2 className="accordion-header mb-0" id="headingOne">
                            {!isShow1 ? <><button className="
        accordion-button
        relative
        flex
        items-center
        w-full
        px-4
        mb-1
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                aria-controls="collapseOne" onClick={() => handleButton1()}>
                                <ImageSpan>
                                    <ImageColumn>
                                        <Image src={imageSrc4} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="dotNetCore_logo" />
                                    </ImageColumn>
                                    <Title>.Net Core</Title>
                                </ImageSpan>
                                <ImageSpan>
                                    <ImageColumn>
                                        <Image src={imageSrc5} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="cSharp_logo" />
                                    </ImageColumn>
                                    <Title>C Sharp</Title>
                                </ImageSpan>
                                <ImageSpan>
                                    <ImageColumn>
                                        <Image src={imageSrc6} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="sqlServer_logo" />
                                    </ImageColumn>
                                    <Title>SQL Server</Title>
                                </ImageSpan>
                                {!isShow1 ? <DownArrow tw="w-6 h-6 text-primary-500" /> : null}
                            </button>
                            </> : null}
                        </h2>
                        {isShow1 ? <ImageContainer>
                            <ImageCard style={{ justifyContent: "flex-end" }}>
                                <ImageSpan>
                                    <ImageColumn2>
                                        <Image src={imageSrc4} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="dotNetCore_logo" />
                                    </ImageColumn2>
                                    <Title>.Net Core</Title>
                                </ImageSpan>
                                <ImageSpan>
                                    <ImageColumn2>
                                        <Image src={imageSrc5} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="cSharp_logo" />
                                    </ImageColumn2>
                                    <Title>C Sharp</Title>
                                </ImageSpan>
                                <ImageSpan>
                                    <ImageColumn2>
                                        <Image src={imageSrc6} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="sqlServer_logo" />
                                    </ImageColumn2>
                                    <Title>SQL Server</Title>
                                </ImageSpan>
                                <ImageSpan>
                                    <ImageColumn1>
                                        <Image src={imageSrc22} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="webApi_logo" />
                                    </ImageColumn1>
                                    <Title>Web API</Title>
                                </ImageSpan>
                                <button onClick={() => handleButton1()}>{isShow1 ? <UpArrow tw="w-6 h-6 text-primary-500" /> : null}</button>
                            </ImageCard>
                            <ImageCard style={{ marginLeft: "2.5rem" }}>
                                <ImageSpan>
                                    <ImageColumn2>
                                        <Image src={imageSrc18} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="mvc_logo" />
                                    </ImageColumn2>
                                    <Title>MVC</Title>
                                </ImageSpan>
                                <ImageSpan>
                                    <ImageColumn1>
                                        <Image src={imageSrc20} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="aspDotNet_logo" />
                                    </ImageColumn1>
                                    <Title>ASP.Net</Title>
                                </ImageSpan>
                                <ImageSpan>
                                    <ImageColumn1>
                                        <Image src={imageSrc16} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="nodejs_logo" style={{ borderRadius: "50%" }} />
                                    </ImageColumn1>
                                    <Title>Node.js</Title>
                                </ImageSpan>
                                <ImageSpan>
                                    <ImageColumn1>
                                        <Image src={imageSrc21} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="ajax_logo" style={{ borderRadius: "50%" }} />
                                    </ImageColumn1>
                                    <Title>AJAX</Title>
                                </ImageSpan>

                            </ImageCard>
                            <ImageCard style={{ marginLeft: "2.5rem" }}>
                                <ImageSpan>
                                    <ImageColumn1>
                                        <Image src={imageSrc19} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="VBDotNet_logo" />
                                    </ImageColumn1>
                                    <Title>VB.Net</Title>
                                </ImageSpan>
                            </ImageCard>
                        </ImageContainer> : null}

                        <div className="accordion-item bg-white border-t border-b border-cream-100">
                            <h2 className="accordion-header mb-0" id="headingTwo">
                                {!isShow2 ? <> <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        px-4
        mb-1
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseTwo" onClick={() => handleButton2()}>

                                    <ImageSpan>
                                        <ImageColumn>
                                            <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="React_logo" />
                                        </ImageColumn>
                                        <Title>React</Title>
                                    </ImageSpan>
                                    <ImageSpan>
                                        <ImageColumn>
                                            <Image src={imageSrc2} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="Redux_logo" />
                                        </ImageColumn>
                                        <Title>Redux</Title>
                                    </ImageSpan>
                                    <ImageSpan>
                                        <ImageColumn>
                                            <Image src={imageSrc3} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="JS_logo" />
                                        </ImageColumn>
                                        <Title>Javascript</Title>
                                    </ImageSpan>
                                    {!isShow2 ? <DownArrow tw="w-6 h-6 text-primary-500" /> : null}
                                </button>
                                </> : null}
                            </h2>
                            {isShow2 ? <ImageContainer>
                                <ImageCard style={{ justifyContent: "flex-end" }}>
                                    <ImageSpan>
                                        <ImageColumn2>
                                            <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="React_logo" />
                                        </ImageColumn2>
                                        <Title>React</Title>
                                    </ImageSpan>
                                    <ImageSpan>
                                        <ImageColumn2>
                                            <Image src={imageSrc2} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="Redux_logo" />
                                        </ImageColumn2>
                                        <Title>Redux</Title>
                                    </ImageSpan>
                                    <ImageSpan>
                                        <ImageColumn2>
                                            <Image src={imageSrc3} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="JS_logo" />
                                        </ImageColumn2>
                                        <Title>Javascript</Title>
                                    </ImageSpan>
                                    <ImageSpan>
                                        <ImageColumn2>
                                            <Image src={imageSrc11} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="bootstrap_logo" style={{ borderRadius: "50%" }} />
                                        </ImageColumn2>
                                        <Title>Bootstrap</Title>
                                    </ImageSpan>
                                    <button onClick={() => handleButton2()}>{isShow2 ? <UpArrow tw="w-6 h-6 text-primary-500" /> : null}</button>
                                </ImageCard>
                                <ImageCard style={{ marginLeft: "2.5rem" }}>
                                    <ImageSpan>
                                        <ImageColumn1>
                                            <Image src={imageSrc10} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="jquery_logo" />
                                        </ImageColumn1>
                                        <Title>Jquery</Title>
                                    </ImageSpan>
                                    <ImageSpan>
                                        <ImageColumn1>
                                            <Image src={imageSrc12} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="sass_logo" />
                                        </ImageColumn1>
                                        <Title>SASS</Title>
                                    </ImageSpan>
                                    <ImageSpan>
                                        <ImageColumn1>
                                            <Image src={imageSrc13} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="tailwind_logo" />
                                        </ImageColumn1>
                                        <Title>Tailwind CSS</Title>
                                    </ImageSpan>
                                    <ImageSpan>
                                        <ImageColumn1>
                                            <Image src={imageSrc14} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="css_logo" />
                                        </ImageColumn1>
                                        <Title>CSS</Title>
                                    </ImageSpan>
                                </ImageCard>
                                <ImageCard style={{ marginLeft: "2.5rem" }}>
                                    <ImageSpan>
                                        <ImageColumn1>
                                            <Image src={imageSrc15} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="html_logo" />
                                        </ImageColumn1>
                                        <Title>HTML</Title>
                                    </ImageSpan>
                                </ImageCard>
                            </ImageContainer>
                                : null}
                        </div>
                        <div className="accordion-item bg-white border-cream-100">
                            <h2 className="accordion-header mb-0" id="headingThree">
                                <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        px-4
        mb-1
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree" onClick={() => handleButton3()}>
                                    <ImageSpan>
                                        <ImageColumn>
                                            <Image src={imageSrc7} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="aws_logo" />
                                        </ImageColumn>
                                        <Title>Amazon Web Services</Title>
                                    </ImageSpan>
                                    <ImageSpan>
                                        <ImageColumn>
                                            <Image src={imageSrc9} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="azure_logo" />
                                        </ImageColumn>
                                        <Title>Microsoft Azure Cloud</Title>
                                    </ImageSpan>
                                    <ImageSpan>
                                        <ImageColumn>
                                            <Image src={imageSrc8} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="gcp_logo" />
                                        </ImageColumn>
                                        <Title>Google Cloud Platform</Title>
                                    </ImageSpan>
                                    {/*{!isShow3 ? <RightArrow tw="w-6 h-6" /> : <DownArrow tw="w-6 h-6" />}*/}
                                </button>
                            </h2>
                            {/*{isShow3 ? <ImageCard>*/}
                            {/*    <ImageSpan>*/}
                            {/*        <ImageColumn>*/}
                            {/*            <Image src={imageSrc17} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} alt="github_logo" />*/}
                            {/*        </ImageColumn>*/}
                            {/*        <Title>Github</Title>*/}
                            {/*    </ImageSpan>*/}
                            {/*</ImageCard> : null}*/}
                        </div>
                    </div>
                </div>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Subheading>{subheading}</Subheading>
                        <Heading>{heading}</Heading>
                        <Description>{description}</Description>
                        <Features>
                            {features.map((feature, index) => (
                                <Feature key={index}>
                                    <FeatureHeadingContainer>
                                        <FeatureIconContainer
                                            iconFilled={iconFilled}
                                            iconRoundedFull={iconRoundedFull}
                                            css={feature.iconContainerCss || iconContainerCss}
                                        >
                                            {<feature.Icon />}
                                        </FeatureIconContainer>
                                        <FeatureHeading>{feature.title}</FeatureHeading>
                                    </FeatureHeadingContainer>
                                    <FeatureDescription>{feature.description}</FeatureDescription>
                                </Feature>
                            ))}
                        </Features>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
}

export default Techstack;