import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

const Container = tw.div`relative bg-gray-300 text-gray-900 m-0 px-4`;

const Content = tw.div`max-w-screen-xl mx-auto pt-8 pb-8`

const FiveColumns = tw.div`flex flex-wrap justify-between`;

const CompanyColumn = tw.div`text-left mb-8 lg:mb-0 w-full lg:w-1/5 md:w-1/4 sm:w-1/4`;

const LinksColumn1 = tw.div`w-1/2 sm:w-1/6 md:w-1/6 lg:w-4/12 mb-8 md:mb-0 text-sm sm:text-base text-left`;

const CopyrightColumn = tw.div`w-full xs:w-3/4 sm:w-3/4 md:w-3/4 lg:w-1/3 mb-8 md:mb-0 text-sm sm:text-base text-left`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LogoContainer = tw.div`flex lg:justify-start`;

const LogoText = tw.h5`ml-0 text-4xl text-primary-500 font-bold`;

const CompanyAddress = tw.p`max-w-xs font-medium text-sm lg:mx-0 lg:mr-4 leading-loose text-left`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-start`

const CopyrightNotice = tw.div``

const Address = tw.div`my-3`

const Footer = () => {

    const scrollToWhoWeAre = () => {
        document.getElementById("whoweare").scrollIntoView({
            behaviour: "smooth"
        });
    }

    const scrollToWhatWeDo = () => {
        document.getElementById("whatwedo").scrollIntoView({
            behaviour: "smooth"
        });
    }

    const scrollToTechstack = () => {
        document.getElementById("techstack").scrollIntoView({
            behaviour: "smooth"
        });
    }

    const scrollToCareer = () => {
        document.getElementById("career").scrollIntoView({
            behaviour: "smooth"
        });
    }

    const scrollToContactUs = () => {
        document.getElementById("contact").scrollIntoView({
            behaviour: "smooth"
        });
    }
    const scrollTop = () => {
        document.getElementById("navbar").scrollIntoView({
            behaviour: "smooth"
        });
    }

    //const scrollTop = () => { // to scroll top the page
    //    window.scrollTo({ top: 0, behavior: 'smooth' });
    //};

    return (
        <Container>
            <Content>
                <FiveColumns>
                    <CompanyColumn>
                        <LogoContainer>
                            <LogoText className="logoFontFamily" onClick={() => scrollTop()}>Karibettu</LogoText>
                        </LogoContainer>
                        <CompanyAddress>
                            Karibettu, established in Bangalore, India in 2006.</CompanyAddress>
                    </CompanyColumn>
                    <LinksColumn1>
                        <ColumnHeading>Bangalore Development Center:</ColumnHeading>
                        <Address>
                            2504/1, 2nd Floor, Samrudh Complex,
                        </Address>
                        <Address>
                            Kodigehalli Road, E Block, Sahakarnagar, 
                        </Address>
                        <Address>
                            Bengaluru 560 092 INDIA
                        </Address>
                    </LinksColumn1>
                    <CopyrightColumn>
                        <ColumnHeading>
                            <CopyrightAndCompanyInfoRow>
                                <CopyrightNotice>Copyright &copy; 2022 by Karibettu Private Limited. All rights reserved. </CopyrightNotice>
                            </CopyrightAndCompanyInfoRow>
                        </ColumnHeading>
                    </CopyrightColumn>
                </FiveColumns>
            </Content>
        </Container>
    );
};

export default Footer;
